.filtered-list__items_height {
  height: 100%;
}

.product__image {
  overflow: hidden;
  flex: 0 0 48px;
  align-self: flex-start;
  width: 48px;
  height: 48px;
  margin-right: 12px;
  cursor: pointer;

  @media screen and (min-width: 768px) {
    .product__image {
      flex-basis: 72px;
      width: 72px;
      height: 72px;
      margin-right: 24px;
    }
  }

  @media screen and (min-width: 568px) {
    .product__image {
      flex-basis: 64px;
      width: 64px;
      height: 64px;
      margin-right: 16px;
    }
  }
}

.product-placeholder {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #7d8d9e;
  font-size: 16px;
  line-height: 24px;

  &.colored-background-1 {
    background-color: #f9d9e2;
  }

  @media screen and (min-width: 768px) {
    .product-placeholder {
      font-size: 22px;
    }
  }
}

.placeholder-titles {
  margin-bottom: 0;

  .placeholder-product {
    padding: 14px;

    .placeholder-image {
      width: 38px;
      height: 38px;
      margin-right: 10px;
      border-radius: 4px;
    }

    .placeholder-product-title {
      width: 101px;
      height: 14px;
      border-radius: 4px;
    }

    .placeholder-description {
      width: 65px;
      height: 14px;
      border-radius: 4px;
    }

    .placeholder-checkbox {
      width: 42px;
      height: 25px;
      border-radius: 4px;
    }
  }

  .placeholder-pagination {
    margin-bottom: 50px;

    .placeholder-showing-status__pages {
      margin-top: 10px;
      margin-bottom: 20px;
      height: 19px;
      width: 200px;
      border-radius: 4px;
    }

    .placeholder-nav__pages {
      height: 40px;
      width: 263px;
      border-radius: 4px;
    }
  }
}

.selected-products {
  font-family: 'HCo Gotham', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, sans-serif;
  color: #1f2328;
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid #b3b2b2;
  font-size: 12px;
  font-weight: 500;
}

.long-list-select-bar .filter-status {
  display: inline-block;
  position: relative;
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  margin: -2px 8px 0 6px;
  padding: 1px;
  border: 1px solid #c5ccd3;
  border-radius: 3px;
  line-height: 30px;
  vertical-align: middle
}

.long-list-select-bar .filter-status--notall::after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: 3px;
  left: 3px;
  height: 2px;
  margin-top: -1px;
  background: #1f2328
}

.long-list-select-bar .filter-status--all::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 1px;
  left: 1px;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26' fill='%231f2328'%3E%3Cpath d='M5 12l5.02 4.9L21.15 4c.65-.66 1.71-.66 2.36 0 .65.67.65 1.74 0 2.4l-12.3 14.1c-.33.33-.76.5-1.18.5-.43 0-.86-.17-1.18-.5l-6.21-6.1c-.65-.66-.65-1.74 0-2.41.65-.65 1.71-.65 2.36.01z'/%3E%3C/svg%3E") no-repeat center
}

.list-dropdown--left--border-none {
  border: none !important;

  &.list-dropdown.list-dropdown--left {
    right: 0;
    left: auto;
  }
}

.list-dropdown__item--custom {
  & li {
    font-size: 14px;
    line-height: 22px;
    color: #24282C;
    padding: 13px 16px !important;
    border-left: 1px solid rgba(168, 182, 194, 0.7);
    border-right: 1px solid rgba(168, 182, 194, 0.7);

    &:first-child {
      border-top: 1px solid rgba(168, 182, 194, 0.7);
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &:last-child {
      border-bottom: 1px solid rgba(168, 182, 194, 0.7);
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}

.filter-status--handler {
  margin-left: 18px;
  font-size: 14px;
  line-height: 22px;
  color: #068EEF;
}

.btn-dropdown-border-left-side {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}