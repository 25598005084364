.notFoundContainer {
  display: flex;
  width: 670px;
  height: 395px;

  .firstColumn {
    display: flex;
    flex: 1;

    .infoContainer {
      margin: auto;

      .code {
        font-weight: 700;
        font-size: 72px;
        line-height: 68.9px;
        margin: 0;
      }

      .description {
        margin-top: 20px;
      }

      .btn {
        margin-top: 20px;
        max-width: unset;
        width: 181px;
      }
    }

  }

  .secondColumn {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: flex-start;

    img {
      margin-top: 30px;
    }
  }
}