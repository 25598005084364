.list-intervals {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid rgba(143, 159, 176, 0.35);

  .collapsible--opened .collapsible__header::after {
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOSAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIuNTk4OSAxMy41NjMzQzIuMjAzMzMgMTMuOTQ3OSAxLjU3MTQ0IDEzLjkzODUgMS4xODc1NSAxMy41NDIxQzAuODAzNjUxIDEzLjE0NTggMC44MTMxMTQgMTIuNTEyNyAxLjIwODY5IDEyLjEyODFMOS4xODA2IDQuMzc2NjJDOS41Njc4MiA0LjAwMDExIDEwLjE4MzYgNC4wMDAxMSAxMC41NzA4IDQuMzc2NjJMMTguNTQyNyAxMi4xMjgxQzE4LjkzODMgMTIuNTEyNyAxOC45NDc4IDEzLjE0NTggMTguNTYzOSAxMy41NDIxQzE4LjE4IDEzLjkzODUgMTcuNTQ4MSAxMy45NDggMTcuMTUyNSAxMy41NjMzTDkuODc1NyA2LjQ4Nzc0TDIuNTk4OSAxMy41NjMzWiIgZmlsbD0iIzdEOEQ5RSIvPgo8L3N2Zz4K) no-repeat center center;
  }

  .collapsible__header:after {
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOSAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3LjE1MjYgNC4zNzY2MkMxNy41NDgxIDMuOTkxOTkgMTguMTggNC4wMDE0NyAxOC41NjM5IDQuMzk3OEMxOC45NDc4IDQuNzk0MTMgMTguOTM4NCA1LjQyNzIyIDE4LjU0MjggNS44MTE4NkwxMC41NzA5IDEzLjU2MzNDMTAuMTgzNyAxMy45Mzk4IDkuNTY3ODcgMTMuOTM5OCA5LjE4MDY2IDEzLjU2MzNMMS4yMDg3NCA1LjgxMTg2QzAuODEzMTczIDUuNDI3MjIgMC44MDM3MDkgNC43OTQxMyAxLjE4NzYxIDQuMzk3OEMxLjU3MTUgNC4wMDE0NyAyLjIwMzM5IDMuOTkxOTkgMi41OTg5NiA0LjM3NjYyTDkuODc1NzYgMTEuNDUyMkwxNy4xNTI2IDQuMzc2NjJaIiBmaWxsPSIjN0Q4RDlFIi8+Cjwvc3ZnPgo=) no-repeat center center;
  }
}