#company-settings {
  .iconable-block__icon {
    margin-right: 24px;
  }

  .react-datepicker_button_clear {
    & .react-datepicker_button_clear_wrapper {
      display: inline-block;
      width: 100%;
      text-align: center;
      margin: 12px 0;
    }
  }

  @media screen and (min-width: 768px) {
    .cta-block .cta-block__cta .btn {
      width: auto;
      min-width: auto;
    }
  }
}

.background-info {
  background-color: #CDE8FC !important;
}

.list-dropdown__item--company {
  & li {
    font-size: 14px;
    line-height: 22px;
    color: #24282C;
    padding: 13px 16px !important;
    border-left: 1px solid rgba(168, 182, 194, 0.7);
    border-right: 1px solid rgba(168, 182, 194, 0.7);

    &:first-child {
      border-top: 1px solid rgba(168, 182, 194, 0.7);
    }

    &:last-child {
      border-bottom: 1px solid rgba(168, 182, 194, 0.7);
    }
  }
}

.react-datepicker-popper {
  z-index: 30;
}

.disabled-color {
  color: #7D8D9E;
}

.company-status_description {
  font-weight: 400;
  margin-top: 8px;
  margin-bottom: 24px;
}

.company-status-settings-interval {
  padding-top: 24px;
  border-top: 1px solid rgba(143, 159, 176, 0.35);

  .company-status-settings-interval_description {
    font-weight: 400;
    margin-bottom: 10px;
  }
}