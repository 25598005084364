.list-element__title:hover span {
  color: #0463a7;
}

.list-element__data-row {
  font-size: 12px;
  line-height: 18px;

  & .quantity-has {
    color: #24282C;
  }

  & .quantity-not {
    color: #F43C36;
  }

  & .product-enabled {
    margin-right: 12px;
    font-weight: 600;
    color: #47B543;
  }

  & .product-disabled {
    margin-right: 12px;
    font-weight: 600;
    color: #A9B7C5;
  }
}