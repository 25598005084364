.cursor-pointer {
  cursor: pointer;
}

.mr-0 {
  margin-right: 0;
}

.ml-0 {
  margin-left: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.mt-0 {
  margin-top: 0;
}

.position-absolute {
  position: absolute;
}

.text-bold {
  font-weight: 600;
}