.list-intervals_item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 14px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(143, 159, 176, 0.35);

  .list-intervals_item__buttons {

    & .list-intervals_item__buttons-not-edit svg:hover path {
      fill: rgb(3, 87, 144);
    }

    & .list-intervals_item__buttons-edit {
      height: 18px;

      & #checkSvg:hover path {
        fill: rgb(49, 141, 46);
      }
      & #closeSvg:hover path {
        fill: rgb(199, 20, 14);
      }
    }

    & svg {
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }
}