.somethingWentWrongContainer {
  display: flex;
  justify-content: center;

  .contentWrapper {
    margin: auto;
    text-align: center;

    .title {
      font-weight: 700;
      font-size: 36px;
      line-height: 34.45px;
      margin-bottom: 0;
    }
  }
}